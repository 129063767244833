import React from 'react';
import IndustriesPage from '../../components/IndustriesPage';

const data = {
    heroSection: {
      heading: ['Electronics', 'manufacturing'], 
      imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/semiconductors-logo.png',
      content: [
        'Solutions to reduce scrap, rework and inspection time of complex electronic parts'
      ]
    }, 
    contentSection: [
      {
        type: 'light', 
        mobileType: 'primary',
        headerContent: {
          labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-eye-icon.png', 
          labelImgDimensions: {
            width: '5.06rem', 
            height: '3.75rem'
          },
          heading: 'Bare PCB Manufacturing', 
          flexDirection: 'row',
          description: 'Replace slow and error-prone manual inspection of bare circuit boards with camera based defect detection software that easily adapts to any board configuration.', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/semiconductors-content-01.png', 
          imgDimensions: {
            width: '45.81rem', 
            height: '32.56rem'
          }
        }, 
        content: [
          {
            title: 'Problem', 
            text: [
              'Your board manufacturing process requires repeated manual inspections after depositing each new layer.', 
              'Inspections are error-prone and missing any defect leads to wasting resources on boards which are ultimately faulty and scrapped, increasing your production costs'
            ]
          }, 
          {
            title: 'Solution', 
            text: [
              "AI powered defect detection software is customized to instantly and accurately identify all possible defects on your board", 
              'The software can be re-calibarated within hours if your board layout changes, giving you complete control over manufacturing and quality'
            ]
          }, 
          {
            title: 'Outcome', 
            text: [
              {
                primaryText: '100% lower cost of quality', 
                description: 'Defects are identified early, significantly reducing manufacturing time spent on faulty boards'
              }, 
              {
                primaryText: '50% fewer defects', 
                description: 'Get a holistic and data-rich overview of the entire inspection life cycle which enables you to find root causes and prevent future defects'
              }
            ]
          }
        ]
      },
      {
        type: 'primary',
        mobileType: 'light', 
        headerContent: {
          labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/camera-icon.png', 
          labelImgDimensions: {
            width: '3.68rem', 
            height: '3rem'
          },
          heading: 'PCB Assembly', 
          flexDirection: 'row-reverse',
          description: 'Augment capabilities of your quality inspectors and AOI system with camera based defect detection that is fast, flexible and lowers false positive rate.', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/semiconductors-content-02.png', 
          imgDimensions: {
            width: '45.75rem', 
            height: '24.56rem'
          }
        }, 
        content: [
          {
            title: 'Problem', 
            text: [
              'Inspection of PCB Assembly is highly specialized and operators require a lot of time to understand each circuit layout', 
              ' AOI Systems have the limitation of producing too many false positives, and take a long time to re-program if layout changes'
            ]
          }, 
          {
            title: 'Solution', 
            text: [
              "AI powered defect detection software is customized to instantly and accurately identify anomalies in circuit assembly ", 
              'This software is re-programmed within hours if circuit layout changes, giving you complete control over manufacturing and quality.'
            ]
          }, 
          {
            title: 'Outcome', 
            text: [
              {
                primaryText: '50% lower scrap', 
                description: 'Accurate anomaly detection minimizes false positives, which significantly reduces scrap and lowers production costs'
              }, 
              {
                primaryText: '50% increased output', 
                description: 'Fast automated detection ensures inspection time for each board is reduced, increasing overall production output'
              }
            ]
          }
        ]
      }
    ]
  }

export default function SemiconductorsPage(){
    return <IndustriesPage data={data} />
}