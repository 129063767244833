import React from 'react'; 
import { Link } from 'gatsby';
import Button from './Button';

export default function GetStartedButton({children, type}){
    return (
        <Button type={type}>
            <Link to='/onboarding'>
                {children}
            </Link>
        </Button>
    )    
}