import React from 'react';

export default function Button({type, children, style, handleClick, classes}){
  let buttonStyle = {
    borderRadius: '2rem',
    boxShadow: '-5px 5px rgba(122, 199, 255)', 
    fontFamily: 'Montserrat'
  }, 
  buttonStylingClasses = '';

  if(type === 'primary'){
    buttonStylingClasses = 'text-white bg-blue-800 font-semibold';
  }
  else if(type === 'light'){
    buttonStylingClasses = 'text-blue-800 border-2 border-solid border-blue-800 bg-white font-semibold';
  }

  buttonStylingClasses += ` ${classes}`; 

  return (
    <button 
      style={{...buttonStyle, ...style}} 
      className={`
        z-20 
        top-0 left-0 
        py-[0.5rem] laptop:py-[0.7rem] 
        px-[1.5rem] laptop:px-[2rem] 
        text-center text-mobile-base laptop:text-base 
        flex items-center justify-center 
        w-full 
        ${buttonStylingClasses}`
      } 
      onClick={handleClick}
    >
      {children}
    </button> 
  )
}