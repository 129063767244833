import React, { useContext } from 'react'; 
import HeroSection from './HeroSection';
import ImgExamplesSection from './ImgExamplesSection';
import { MobileScreenContext } from '../Page';

export default function ContentSection({data}){
    const {type, headerContent, content, imgExamples, mobileType} = data;
    const { mobileScreen } = useContext(MobileScreenContext);

    function mobileBackgroundColor(type){
        return type === 'primary' && 'bg-sky-200'
    }

    if(!mobileScreen){
        return (
            <section className={`rounded-2xl ${type === 'primary' && 'bg-sky-200'} py-20`}>
                <div className='laptop:max-w-10/12 mx-auto'>
                    <div className={`flex  ${type === 'primary'? 'flex-row': 'flex-row-reverse'} justify-between`} style={{flexDirection: headerContent.flexDirection}}>
                        <div className={`flex flex-col laptop:max-w-[33%] self-end pb-6 `}>
                            <img src={headerContent.labelImgUrl} alt='header_label' style={headerContent.labelImgDimensions}/>
                            <h3 className='font-bold text-[1.75rem] my-5 leading-[40px]'>{headerContent.heading}</h3>
                            <p className='text-normal'>{headerContent.description}</p>
                        </div>
                        <div>
                            <img src={headerContent.imgUrl} alt='header_img' style={headerContent.imgDimensions}/>
                        </div>
                    </div>
                    
                    {/* Problems and Solutions section */}
                    <div className='grid grid-cols-12 mt-10'>
                        {content.filter(contentData => contentData.title !== 'Outcome').map((contentData, index) => {
                            const {title, text} = contentData;
    
                            let colStart = '';
                            switch(index){
                                case 0: 
                                    colStart = 'col-start-1'; 
                                    break; 
                                case 1: 
                                    colStart = 'col-start-5'; 
                                    break;
                            }
    
                            return (
                                <div className={`col-span-3 ${colStart}`}>
                                    <h3 className='text-3xl font-medium mb-4'>{title}:</h3>
                                    {text.map(textData => <p className='pt-4 pb-2 w-[96%]'>{textData}</p>)}
                                </div>
                            )
                        })}
    
                        {/* Outcome section */}
                        {content.filter(contentData => contentData.title === 'Outcome').map(contentData => {
                            const {title, text} = contentData;
    
                            return (
                                <div className='col-span-4 col-start-9'>
                                    <h3 className='text-3xl font-medium'>{title}:</h3>
                                    {text.map(textData => {
                                        const {primaryText, description} = textData;
    
                                        return (
                                            <div className='pt-7 pb-2'>
                                                <p className='text-blue-800 font-bold text-xl mb-1'>{primaryText}</p>
                                                <p>{description}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
    
                {imgExamples && 
                    <ImgExamplesSection data={imgExamples} />
                }
            </section>
        )
    }
    else{
        return (
            <section className={`grid grid-cols-1 py-10 px-3 ${mobileType ? mobileBackgroundColor(mobileType) : mobileBackgroundColor(type)} text-mobile-base rounded-2xl`}>
                <div className='max-w-full mobile-lg-tablet:max-w-10/12 mobile-tablet-one:max-w-3/4 mx-auto'>
                    <img src={headerContent.mobileImgUrl ? headerContent.mobileImgUrl : headerContent.imgUrl} alt='header_label' className='mx-0 mobile-lg-tablet:mx-auto'/>
                    <div className='flex gap-7 items-center mobile-lg-tablet:justify-center mt-2 mb-2'>
                        <img src={headerContent.labelImgUrl} alt='header_label' style={headerContent.labelImgDimensions} className='scale-[65%]'/>
                        <p className='text-mobile-heading-lg font-medium'>{headerContent.heading}</p>
                    </div>
                    <p className='mobile-lg-tablet:text-center'>{headerContent.description}</p>

                    {/* Problems and Solutions section */}
                    {content.filter(contentData => contentData.title !== 'Outcome').map(contentData => {
                        const {title, text} = contentData;

                        return (
                            <div className='flex flex-col mt-4'>
                                <h3 className='text-mobile-heading-lg font-bold'>The {title}:</h3>
                                {text.map(textData => <p className='py-2'>{textData}</p>)}
                            </div>
                        )
                    })}

                    {/* Outcomes section */}
                    {content.filter(contentData => contentData.title === 'Outcome').map(contentData => {
                        const {title, text} = contentData;

                        return (
                            <div className='mt-4'>
                                <h3 className='text-mobile-heading-lg font-bold'>{title}</h3>
                                <div className='flex gap-5 justify-between'>
                                    {text.map(textData => {
                                        const {primaryText, description} = textData;

                                        return (
                                            <div className='flex flex-col w-3/4'>
                                                <p className='text-[0.75rem] py-1 text-blue-800 font-bold'>{primaryText}</p>
                                                <p className=''>{description}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}

                    {imgExamples && 
                        <ImgExamplesSection data={imgExamples}/>
                    }
                </div>
            </section>
        )
    }
}