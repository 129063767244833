import React, { useContext } from 'react';
import { MobileScreenContext } from './Page';
import ProblemsCard from './ui/ProblemsCard';

export default function ProblemsSection({data}){
  const {heading, content} = data;
  const { mobileScreen } = useContext(MobileScreenContext);

  function getMaxContentLength(){
    let maxContentLength = 0; 
    content.forEach(item => {
      if(item.text.length > maxContentLength){
        maxContentLength = item.text.length;
      }
    })

    return maxContentLength;
  }

  return (
    <section className='px-4 mobile-one:px-6 tablet:px-20'>
        <h2 className='
          text-mobile-heading-lg mobile-tablet-one:text-xl laptop:text-[2rem] 
          font-medium laptop:font-normal
        '>
          {heading}
        </h2>
        <div className='
          grid 
          grid-cols-1 laptop:grid-cols-3 
          gap-y-5 laptop:gap-y-0 
          laptop:gap-x-5
          py-5 laptop:py-14
        '>
            {content.map((cardData, index)=> {
                return (
                  <ProblemsCard
                    key={`primaryCard_${cardData.title}`} 
                    data={cardData} 
                    index={index} 
                    mobileScreen={mobileScreen}
                    maxContentLength={getMaxContentLength()}
                  />
                ) 
            })}
        </div>
    </section>
  )    
}