import React from 'react';
import Link from 'gatsby-link';
import Button from '../ui/Button';

export default function HeroSection({data}){
  const {heading, content, imageUrl} = data;

  return (
    <section className='
      flex flex-col 
      max-w-11/12 mobile-lg-tablet:max-w-3/4 mobile-tablet-one:max-w-[65%] laptop:max-w-10/12 large:max-w-3/4 
      mx-auto 
      pt-5 laptop:pt-10 
      pb-12 laptop:pb-20
    '>
      <div className='
        flex 
        flex-col laptop:flex-row 
        items-start laptop:items-center 
        mb-4 laptop:mb-7
      '>
          <img 
            src={imageUrl} 
            alt='industries_hero_label' 
            className='
              scale-[50%] laptop:scale-100 
              -mb-6 laptop:mb-0
              -ml-4 laptop:ml-0 
          '/>
          <h1 className='
            font-bold 
            text-mobile-heading-xl laptop:text-5xl 
            mt-5 laptop:ml-5
            laptop:mt-0 
          '>
            <span className='block laptop:inline text-blue-800'>
              {heading[0]}
            </span> 
            {heading[1]}
          </h1>   
      </div>

      <div className='
        w-full 
        text-mobile-base laptop:text-2xl 
        mb-4
      '>
        {content.map(text => {
            return <p className='laptop:max-w-2/3 py-2 laptop:py-4'>{text}</p>
        })}
      </div>

      <div className='laptop:w-48'>
        <Link to='/contact-us'>
          <Button type="primary">
            Book a demo
          </Button>
        </Link>
      </div>
    </section>
  )
}