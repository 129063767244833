import React from 'react';
import Button from './ui/Button';
import GetStartedButton from './ui/GetStartedButton';

export default function SetupSection({type}){
  let stylingClasses; 

  switch(type){
    case 'light': 
      stylingClasses = 'bg-transparent text-black';
      break;
    case 'primary': 
      stylingClasses = 'bg-blue-800 rounded-3xl text-white py-5';
        break;
    case 'primary-light': 
      stylingClasses = 'bg-sky-200 rounded-3xl text-black py-5'; 
      break;
  }

  return (
    <section 
      className={stylingClasses} 
      style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
    >
      <div className={`
        flex 
        flex-col laptop:flex-row 
        laptop:gap-5 py-5 laptop:py-10 
        max-w-11/12 mobile-lg-tablet:max-w-10/12 mobile-tablet-one:max-w-[70%] mobile-tablet-two:max-w-[55%] laptop:max-w-3/4 
        mx-auto 
        ${type === 'light' ? 'laptop:mt-10': 'mt-3'}
      `}>
        <div className='
          font-medium 
          laptop:justify-self-end 
          laptop:px-0
        '>
          <h2 className='
            text-mobile-heading-lg laptop:text-[2rem] 
            laptop:w-10/12
          '>
            Setup on your shop floor in days
          </h2>
        </div>
        <div className='
          flex 
          gap-4 mobile-lg-tablet:gap-10 mobile-tablet-one:gap-12 mobile-tablet-two:gap-16 laptop:gap-5 
          py-2 laptop:py-0 
          laptop:px-0
        '>
          <p className='
            text-mobile-base laptop:text-base 
            large:mt-2 
            pt-2 
            w-1/2
          '>
            See how you can easily implement this solution in your factory within days and minimum up-front cost. 
          </p>
          <div className='
            w-36 laptop:w-60 large:w-2/3 
            mt-3 laptop:mt-2 
            laptop:pl-5
          '>
            <GetStartedButton type={type === 'primary-light'? 'primary': 'light'}>
              Get started now
            </GetStartedButton>
          </div>
        </div>
      </div>
    </section>
  )
}