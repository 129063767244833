import React from 'react';
import Page from './Page';
import HeroSection from './Industries/HeroSection';
import SetupSection from './SetupSection';
import ContentSection from './Industries/ContentSection';
import ProblemsSection from './ProblemsSection';



export default function IndustriesPage({data}){
    const {heroSection, contentSection, problemsSection} = data;

    return (
        <Page navbarButtonStyle='primary' selectedPage='Industries'>
            <HeroSection data={heroSection} />
            {problemsSection && <ProblemsSection data={problemsSection} />}

            {contentSection.map(contentSectionData => {
                return <ContentSection data={contentSectionData} />
            })}
            <SetupSection type='primary' />
        </Page>
    )
}