import React from 'react';

export default function ImgExamplesSection({data}){
    return (
        <div className='font-medium text-black laptop:max-w-10/12 mx-auto mt-10'>
            <p className='text-mobile-heading-lg laptop:text-xl'>{data.title}</p>
            <div className='grid grid-cols-3'>
                {data.content.map(contentData => {
                    const {text, imgUrl} = contentData;

                    return (
                        <div className='laptop:pt-5 flex flex-col-reverse laptop:flex-col'>
                            <img src={imgUrl} alt='example_image' style={{width: '11.43rem', height: '5.62rem'}} className='scale-[90%] laptop:scale-100'/>
                            <p className='mt-3 text-[0.75rem] laptop:text-xl pl-1.5 laptop:pl-0'>{text}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}