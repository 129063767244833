import React from 'react';

export default function ProblemsCard({data, index, mobileScreen, maxContentLength}){
  const {title, text, imgUrl } = data;

  let positionX = '';
  if(mobileScreen){
    switch(index){
      case 1: 
        positionX = 'translate-x-6 mobile-tablet-two:translate-x-10';
        break;
      case 2: 
        positionX = 'translate-x-12 mobile-tablet-two:translate-x-20'; 
        break;
      default: 
        positionX = 'tranlate-x-0';
    }
  }

  function getTextContent() {
    let str = text; 

    if(str.length < maxContentLength){
      let diff = maxContentLength - str.length;
      for(let i = 0; i < diff; i++){
        str += ' ';
      }
    }
    
    return str;
  }

  return (
    <div className={`
      w-10/12 mobile-lg-tablet:w-11/12 mobile-tablet-two:w-[85%] laptop:w-full 
      ${positionX}
      primary-gradient-border
      py-3 laptop:py-4 
      px-4 laptop:px-7 
    `}>
      <div className='
        flex items-center 
        gap-x-2 laptop:gap-x-4
      '>
        <img 
          src={imgUrl} 
          alt={title + "_logo"} 
          className='h-[1.81rem] laptop:h-[2.81rem]'
        />

        {title.map(titleText => (
          <h3 className='
            font-bold 
            text-mobile-heading-md laptop:text-xl
          '>
            {titleText}
          </h3>
        ))}
      </div>

      <h5 className='
        text-mobile-base laptop:text-base 
        mt-2 laptop:mt-4
      '>
        {getTextContent()}
      </h5>
    </div>
  )
}